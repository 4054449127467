import React from "react";
import {Table, TableHeading, TableCell, Skeleton, IconButton, Link as EdsLink} from "@nike/eds";
import {Link} from "react-router-dom";
import {LocationConfigurationDto} from "../../../generated-sources";
import {URL_PRINTERS} from "../../shared/Constants";

function LocationSearchResults(props) {

    const headers = [
        {Header: "Name"},
        {Header: "Type"},
        {Header: "Printers"},
    ];

    function LocationSearchResultItem(props) {
        let searchResult: LocationConfigurationDto = props.searchResult;
        return (
            <tr>
                <TableCell width={"15%"}>{searchResult.locationName}</TableCell>
                <TableCell width={"15%"}>{searchResult.locationType}</TableCell>
                <TableCell width={"65%"}>
                    {searchResult.printers ? searchResult.printers.map((printer, index, array) => (
                        <EdsLink variant='secondary' as={Link} to={URL_PRINTERS + "/" + searchResult.node + "/" + encodeURIComponent(printer)}>{printer + (index < array.length - 1 ? ',' : '')}</EdsLink>
                    )) : ""}
                </TableCell>
                <TableCell width={"5%"}>
                    <IconButton
                        variant="ghost"
                        as={Link}
                        to={searchResult.node + "/" + encodeURIComponent(searchResult.locationName)}
                        label=""
                        icon="Edit"
                    />
                </TableCell>
            </tr>
        );
    }

    return (
        <Table style={{wordBreak: "break-word"}}>
            <thead>
            <tr>
                {headers.map(column => (<TableHeading>{column.Header}</TableHeading>))}
            </tr>
            </thead>
            <tbody>
            {props.loading &&
                <>
                    <tr>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"}/></TableCell>
                    </tr>
                </>
            }
            {props.searchResults && props.searchResults.map(searchResult => (
                <LocationSearchResultItem searchResult={searchResult}/>
            ))}
            </tbody>
        </Table>
    );
}

export {LocationSearchResults};


