import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom'
import {Button, ButtonGroup, Card, Icon, Modal, Select, TextField} from "@nike/eds";
import {PrinterService} from "../../shared/PrinterService";

import {LocationConfigurationDto} from "../../../generated-sources";
import {SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {URL_LOCATIONS} from "../../shared/Constants";
import {isValidLocationName} from "../../../util/utils";


export function LocationDetail(props) {

    type OptionType = {
        value: string;
        label: string;
    };

    const typeOptions = [
        {value: "Address location", label: "Address location"},
        {value: "Packstation", label: "Packstation"},
        {value: "Prepstreet", label: "Prepstreet"},
        {value: "Spur", label: "Spur"},
        {value: "Unit handling station", label: "Unit handling station"},
    ];

    const snackbarCtx = useContext(SnackbarContext);
    const params = useParams();
    const navigate = useNavigate();
    const printerService: PrinterService = props.printerService;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const node: string = params.node!;
    const [name, setName] = useState<string>("");
    const [type, setType] = useState<OptionType>();

    const [printers, setPrinters] = useState<OptionType[]>();
    const [printersOptions, setPrintersOptions] = useState<OptionType[]>();

    useEffect(() => {
        printerService.findAllPrinters(node)
            .then(printers => {
                    setPrintersOptions(printers.data.map(printer => {
                        return {value: printer.printerName, label: printer.printerName};
                    }))
                }
            );

        if (params.locationName) {
            printerService.findLocation(node, decodeURIComponent(params.locationName)).then(
                location => {
                    setName(location.data.locationName)
                    if (location.data.locationType) {
                        setType({value: location.data.locationType, label: location.data.locationType});
                    }
                    setPrinters(location.data.printers?.map(printer => {
                        return {value: printer, label: printer};
                    }))
                }
            ).catch(
                error => {
                    if (error.response.status === 404) {
                        snackbarCtx.displayMsg("Location with name " + params.locationName + " not found.", SnackbarStatus.error);
                        navigate(URL_LOCATIONS + "/" + params.node + "/");
                    }
                }
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function isFormValid(): boolean {
        if (!name || !isValidLocationName(name)) {
            return false;
        }
        return true;
    }

    const createOrUpdateLocation = (e) => {
        if (!isFormValid()) {
            snackbarCtx.displayMsg("Complete all fields", SnackbarStatus.error);
            return;
        }

        let location: LocationConfigurationDto = {
            node: node,
            locationName: params.locationName ?? name,
            locationType: type ? type.value : undefined,
            printers: printers ? printers.map(location => location.value) : []
        }

        if (params.locationName) {
            printerService.saveLocation(location)
                .then(r => {
                    console.log(r.status);
                    snackbarCtx.displayMsg("Location successfully saved", SnackbarStatus.success);
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.response.data.message, SnackbarStatus.error);
                });
        } else {
            printerService.createLocation(location)
                .then(r => {
                    snackbarCtx.displayMsg("Location successfully created", SnackbarStatus.success);
                    navigate(encodeURIComponent(location.locationName));
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.response.data.message, SnackbarStatus.error);
                });
        }
    }

    const showDeleteConfirmationModal = (e) => {
        e.preventDefault();
        setShowDeleteConfirmation(true);
    }

    const deleteLocation = () => {
        if (params.locationName) {
            printerService.deleteLocation(node, params.locationName)
                .then(r => {
                    setShowDeleteConfirmation(false);
                    navigate(URL_LOCATIONS);
                    snackbarCtx.displayMsg("Location successfully deleted", SnackbarStatus.success);
                })
                .catch(error => {
                    snackbarCtx.displayMsg(error.response.data.message, SnackbarStatus.error);
                });
        }
    }

    const printersChangeHandler = (event) => {
        setPrinters(event);
    }

    const typeChangeHandler = (event) => {
        setType(event);
    }

    return (
        <form id="location-detail">
            <Card className="eds-flex eds-flex--direction-column eds-gap--16">
                <Button className="eds-flex--align-self-flex-start"
                        onClick={() => navigate(-1)}
                        variant="primary"
                        size={"small"}>
                    <Icon name="CaretLeft" backgroundShape="square"/>
                    Back to overview
                </Button>
                <TextField
                    id="name"
                    label="Name"
                    placeholder="Name of the location"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    readOnly={!!params.locationName}
                    hasErrors={!name || !isValidLocationName(name)}
                    errorMessage={name ? "Location name should not exceed 15 chars" : "Name is required"}
                />
                <Select
                    id="type"
                    label="Type"
                    placeholder="Type of the location"
                    value={type}
                    options={typeOptions}
                    onChange={typeChangeHandler}
                />
                <Select
                    id="printers"
                    label="Printers"
                    isMulti
                    value={printers}
                    options={printersOptions}
                    onChange={printersChangeHandler}
                />
                <div
                    className="eds-grid--m-col-3 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end eds-gap--16 eds-flex eds-flex--direction-row">
                    { // Create or update always visible
                        <Button className="eds-button eds-button--primary"
                                onClick={createOrUpdateLocation}
                                variant="primary"
                                size={"small"}
                                disabled={!isFormValid()}
                        >
                            <Icon name="CheckCircle" backgroundShape="square"/>
                            {params.locationName ? "Save location" : "Create location"}
                        </Button>
                    }
                    {params.locationName &&
                        <Button
                            onClick={(e) => showDeleteConfirmationModal(e)}
                            variant="primary"
                            size={"small"}
                        >
                            <Icon name="Delete" backgroundShape="square"/>
                            Delete location
                        </Button>
                    }
                </div>
            </Card>
            <Modal
                onDismiss={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
                isOpen={showDeleteConfirmation}
                headerSlot={"Delete location"}
                footerSlot={
                    <ButtonGroup>
                        <Button onClick={deleteLocation} size="small">Delete</Button>
                        <Button
                            onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
                            size="small"
                            variant="secondary"
                        >Cancel</Button>
                    </ButtonGroup>
                }
            >
                <p className="eds-type--body-1"> Are you sure you want to delete the location? </p>
            </Modal>
        </form>
    );
}
