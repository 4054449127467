import {Card, IconButton, Label, StatusIndicator} from "@nike/eds";
import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from 'react-router-dom'
import {CupsJob} from "../../../graphql/api";
import {formatDate, mapSource, SnackbarContext, SnackbarStatus} from "node.glds-react-component-library";
import {PropertyValue} from "../../shared/PropertyValue";

function CupsJobDetailHeader(props) {

    const emptyRequest: CupsJob | Record<string, never> = {};
    const navigate = useNavigate();
    const [data, setData] = useState(emptyRequest)
    const snackbarCtx = useContext(SnackbarContext);

    const cupsJobService = props.cupsJobService

    useEffect(() => {
        cupsJobService.getCupsJob(props.hashKey).then(result => {
            if (result.data.getCupsJob === null) {
                snackbarCtx.displayMsg("No CUPS data available for this job. Try refresh to fetch the data again.", SnackbarStatus.neutral);
            }
            setData(result.data.getCupsJob)
        })
            .catch(error => {
                snackbarCtx.displayMsg(error.message, SnackbarStatus.error);
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card className="eds-grid eds-grid--m-cols-6">
            <div className="eds-grid--m-col-5">
                <Label font="title-6">
                    {props.hashKey}
                </Label>
            </div>
            <div className="eds-grid--m-col-1 eds-grid--item-justify-end">
                <IconButton
                    onClick={() => navigate(-1)}
                    icon="CaretLeft"
                    style={{marginRight: "5px"}}
                    size={"small"}
                    label={""}/>
                <IconButton
                    color="var(--eds-color-white)"
                    onClick={() => window.location.reload()}
                    icon="Repeat"
                    style={{marginRight: "5px"}}
                    size={"small"}
                    label={""}/>
            </div>

            <PropertyValue label="Source" value={mapSource(data.tenant)}/>
            <PropertyValue label="Printer" value={data.printer}/>
            <PropertyValue label="Cups instance" value={data.cupsInstance}/>
            <PropertyValue label="Start timestamp" value={formatDate(data.epochStartTimestampUTC)}/>
            <PropertyValue label="End timestamp" value={data.epochEndTimestampUTC ? formatDate(data.epochEndTimestampUTC) : ""}/>
            <PropertyValue label="Status"
                           value={(data.status === null && <StatusIndicator status="inactive" size="large" label="Unknown"/>)
                               || (data.status === "PENDING" && <StatusIndicator status="neutral" size="large" label="Pending"/>)
                               || (data.status === "COMPLETED" && <StatusIndicator status="success" size="large" label="Completed"/>)
                               || (data.status === "CANCELLED" && <StatusIndicator status="danger" size="large" label="Cancelled"/>)}
            />
            <PropertyValue label="Job id" value={data.jobId}/>
            <PropertyValue label="Total duration (ms)" value={data.epochEndTimestampUTC ? data.epochEndTimestampUTC - data.epochStartTimestampUTC : ""}/>

        </Card>
    );
}

export {CupsJobDetailHeader};