import React from "react";
import {TableCell} from "@nike/eds";
import { IconButton } from "@nike/eds";
import {Link} from "react-router-dom";
import {formatDate, mapSource} from "node.glds-react-component-library";

function CupsJobSearchResultItem(props) {

    return (
        <tr>
            <TableCell width={"15%"}>{formatDate(props.searchResult.epochStartTimestampUTC)}</TableCell>
            <TableCell width={"10%"}>{props.searchResult.jobId}</TableCell>
            <TableCell width={"15%"}>{mapSource(props.searchResult.tenant)}</TableCell>
            <TableCell width={"15%"}>{props.searchResult.printer}</TableCell>
            <TableCell width={"15%"}>{props.searchResult.status}</TableCell>
            <TableCell width={"20%"}>{props.searchResult.cupsInstance}</TableCell>
            <TableCell width={"5%"}>
                <IconButton
                    variant="ghost"
                    as={Link}
                    to={"logging/" + props.searchResult.hashKey}
                    label=""
                    icon="Show"
                />
            </TableCell>
        </tr>
    );
}

export {CupsJobSearchResultItem};
